import { PrintLayout } from '@/@types/print';
import { PRINT_LAYOUT_1 } from '@/modules/utils/print-layouts';
import { useState } from 'react';

type UsePrintControlsReturn = {
  selectedLayout: PrintLayout;
  // eslint-disable-next-line no-unused-vars
  handleLayoutChange: (layout: PrintLayout) => void;
  copies: number;
  // eslint-disable-next-line no-unused-vars
  handleCopiesChange: (value: string | number) => void;
  isPrinting: boolean;
  // eslint-disable-next-line no-unused-vars
  handlePrintingChange: (value: boolean) => void;
};

const STORAGE_KEY = 'aguaamigao.selectedPrintLayout';

export function usePrintControls(
  initialLayout: PrintLayout = PRINT_LAYOUT_1
): UsePrintControlsReturn {
  const [selectedLayout, setSelectedLayout] = useState<PrintLayout>(() => {
    if (typeof window !== 'undefined') {
      const savedLayout = localStorage.getItem(STORAGE_KEY) as PrintLayout;
      return savedLayout || initialLayout;
    }
    return initialLayout;
  });

  const [copies, setCopies] = useState(1);
  const [isPrinting, setIsPrinting] = useState(false);

  const handleLayoutChange = (layout: PrintLayout) => {
    setSelectedLayout(layout);
    if (typeof window !== 'undefined') {
      localStorage.setItem(STORAGE_KEY, layout);
    }
  };

  const handleCopiesChange = (value: string | number) => {
    const numValue = typeof value === 'string' ? parseInt(value, 10) : value;
    setCopies(numValue);
  };

  const handlePrintingChange = (value: boolean) => {
    setIsPrinting(value);
  };

  return {
    selectedLayout,
    handleLayoutChange,
    copies,
    handleCopiesChange,
    isPrinting,
    handlePrintingChange,
  };
}
