import { Menu, MenuItem } from '@mui/material';
import { ElementType } from 'react';

interface MenuItemProps {
  icon?: ElementType;
  title: string;
  onClick: () => void;
}

interface CustomMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  menuItems: MenuItemProps[];
}

export function CustomMenu({ anchorEl, onClose, menuItems }: CustomMenuProps) {
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      id="custom-menu"
      open={open}
      onClose={(event: any) => {
        event.stopPropagation();
        onClose();
      }}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {menuItems.map((item) => {
        const ItemIcon = item.icon;

        return (
          <MenuItem
            key={item.title}
            onClick={(event) => {
              event.stopPropagation();
              item.onClick();
              onClose();
            }}
          >
            {ItemIcon ? (
              <>
                <ItemIcon fontSize="small" color="action" sx={{ mr: 1 }} />
                {item.title}
              </>
            ) : (
              item.title
            )}
          </MenuItem>
        );
      })}
    </Menu>
  );
}
