import { Output } from '@/@types/outputs';
import { CustomMenu, Modal } from '@/components/common';
import { convertNumberToMoney, formatDate } from '@/utils';
import { Close, Print } from '@mui/icons-material';
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { OutputPrint } from '../OutputPrint';
import { OutputPrintSimple } from '../OutputPrint/OutputPrintSimple';
import { OutputStatusLabelBullet } from '../OutputStatusLabelBullet';
import { OutputViewModalPlaceholder } from './OutputViewModalPlaceholder';

interface IOutputViewModalProps {
  output?: Output;
  isOpen: boolean;
  isLoading: boolean;
  isFetching: boolean;
  onClose: () => void;
}

export function OutputViewModal({
  output,
  isOpen,
  onClose,
  isLoading,
  isFetching,
}: IOutputViewModalProps) {
  const [printMenuElement, setPrintMenuElement] = useState<null | HTMLElement>(
    null
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const outputPrintRef = useRef(null);
  const outputPrintSimpleRef = useRef(null);

  const handlePrintOutput = useReactToPrint({
    content: () => outputPrintRef.current,
  });

  const handlePrintOutputSimple = useReactToPrint({
    content: () => outputPrintSimpleRef.current,
  });

  return (
    <>
      <Modal open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ p: 2, alignItems: 'center' }}
          display="flex"
          justifyContent="space-between"
        >
          {isLoading ? (
            <Skeleton width={isMobile ? '80%' : '50%'} height="3rem" />
          ) : (
            output?.operation.name
          )}
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="print"
              onClick={(event) => setPrintMenuElement(event.currentTarget)}
            >
              <Print />
            </IconButton>
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        {isFetching ? (
          <Box>
            <LinearProgress />
          </Box>
        ) : null}
        {isLoading ? (
          <OutputViewModalPlaceholder />
        ) : (
          <DialogContent dividers sx={{ py: 3, px: 2 }}>
            <Stack spacing={3}>
              <Stack spacing={{ xs: 1, sm: 0.5 }}>
                <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                  <Typography variant="body1" fontWeight="bold">
                    Informações
                  </Typography>
                  <Divider sx={{ flexGrow: 1 }} />
                </Stack>
                <Stack spacing={0.5} direction="row">
                  <Typography variant="body2">Número:</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    #{output?.number}
                  </Typography>
                </Stack>
                <Stack spacing={0.5} direction="row">
                  <Typography variant="body2">Data:</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {formatDate(output?.issueDate!)}
                  </Typography>
                </Stack>
                <Stack
                  spacing={{ xs: 0, sm: 0.5 }}
                  direction={{ xs: 'column', sm: 'row' }}
                >
                  <Typography variant="body2">Cliente:</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {output?.customer.name}
                  </Typography>
                </Stack>
                <Stack
                  spacing={{ xs: 0, sm: 0.5 }}
                  direction={{ xs: 'column', sm: 'row' }}
                >
                  <Typography variant="body2">Colaborador:</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {output?.staff?.name || ''}
                  </Typography>
                </Stack>
                {output?.priceTable && (
                  <Stack
                    spacing={{ xs: 0, sm: 0.5 }}
                    direction={{ xs: 'column', sm: 'row' }}
                  >
                    <Typography variant="body2">Tabela de Preço:</Typography>
                    <Typography variant="body2" fontWeight="bold">
                      {output?.priceTable?.name || ''}
                    </Typography>
                  </Stack>
                )}
                {output?.paymentTerm && (
                  <Stack
                    spacing={{ xs: 0, sm: 0.5 }}
                    direction={{ xs: 'column', sm: 'row' }}
                  >
                    <Typography variant="body2">
                      Condição de Pagamento:
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                      {output?.paymentTerm?.name || ''}
                    </Typography>
                  </Stack>
                )}
                {output?.note && (
                  <Stack
                    spacing={{ xs: 0, sm: 0.5 }}
                    direction={{ xs: 'column', sm: 'row' }}
                  >
                    <Typography variant="body2">Observações:</Typography>
                    <Typography variant="body2" fontWeight="bold">
                      {output?.note || ''}
                    </Typography>
                  </Stack>
                )}
                <Stack
                  spacing={{ xs: 0, sm: 0.5 }}
                  direction={{ xs: 'column', sm: 'row' }}
                >
                  <Typography variant="body2">Criado por:</Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {output?.user?.name || ''}
                  </Typography>
                </Stack>
                <Stack spacing={0.5} alignItems="center" direction="row">
                  <Typography variant="body2">Status:</Typography>
                  <OutputStatusLabelBullet
                    isFinished={output?.isFinished!}
                    situation={output?.situation}
                    deletedAt={output?.deletedAt}
                  />
                </Stack>
                {!!output?.deletedAt && (
                  <>
                    <Stack
                      spacing={{ xs: 0, sm: 0.5 }}
                      direction={{ xs: 'column', sm: 'row' }}
                    >
                      <Typography variant="body2">Excluído por:</Typography>
                      <Typography
                        variant="body2"
                        fontStyle="italic"
                        color="grey.700"
                      >
                        {output?.deletedBy?.name || ''}
                      </Typography>
                    </Stack>
                    <Stack
                      spacing={{ xs: 0, sm: 0.5 }}
                      direction={{ xs: 'column', sm: 'row' }}
                    >
                      <Typography variant="body2">
                        Motivo de cancelamento:
                      </Typography>
                      <Typography
                        variant="body2"
                        fontStyle="italic"
                        color="grey.700"
                      >
                        {output?.deletionReasonText || ''}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
              <Stack spacing={{ xs: 1, sm: 0.5 }}>
                <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                  <Typography variant="body1" fontWeight="bold">
                    Produtos
                  </Typography>
                  <Divider sx={{ flexGrow: 1 }} />
                </Stack>
                <Grid container rowSpacing={0.5}>
                  {output?.products.map((product) => (
                    <React.Fragment key={product.id}>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body2">
                          {product.outputProduct.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Typography variant="body2">
                          {product.productQuantity}{' '}
                          {product.outputUnit.abbreviation} x{' '}
                          {convertNumberToMoney(product.unitaryValue)} ={' '}
                          {convertNumberToMoney(product.subtotal)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 1 }} />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={1}
                  pt={1}
                >
                  {output?.totalGross !== output?.total ? (
                    <>
                      <Typography variant="body2">
                        Subtotal Bruto:{' '}
                        {convertNumberToMoney(output?.totalGross!)}
                      </Typography>
                      <Typography variant="body2">
                        Desconto: {convertNumberToMoney(output?.totalDiscount!)}
                      </Typography>
                      <Typography variant="body2" fontWeight="bold">
                        Total: {convertNumberToMoney(output?.total!)}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body2" fontWeight="bold">
                      Total: {convertNumberToMoney(output?.total!)}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              {output?.installments?.length ? (
                <Stack spacing={{ xs: 1, sm: 0.5 }}>
                  <Stack spacing={{ xs: 1, sm: 0.5 }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      mb={1}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Parcelas
                      </Typography>
                      <Divider sx={{ flexGrow: 1 }} />
                    </Stack>
                  </Stack>
                  <Grid container rowSpacing={0.5}>
                    {output?.installments.map((installment) => (
                      <React.Fragment key={installment.id}>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="body2">
                            {installment.documentType.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Typography variant="body2">
                            {convertNumberToMoney(installment.amount)}{' '}
                            {formatDate(
                              installment.dueDate as unknown as string,
                              'pt-BR',
                              {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              }
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider sx={{ my: 1 }} />
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Stack>
              ) : null}
            </Stack>
          </DialogContent>
        )}
      </Modal>
      <Box display="none">
        <OutputPrint output={output} containerRef={outputPrintRef} />
        <OutputPrintSimple
          output={output}
          containerRef={outputPrintSimpleRef}
        />
      </Box>
      <CustomMenu
        anchorEl={printMenuElement}
        onClose={() => setPrintMenuElement(null)}
        menuItems={[
          { title: 'Imprimir (Bobina)', onClick: handlePrintOutput },
          {
            title: 'Imprimir (Bobina Simples)',
            onClick: handlePrintOutputSimple,
          },
        ]}
      />
    </>
  );
}
