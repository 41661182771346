/* eslint-disable no-nested-ternary */
import React from 'react';
import { theme } from '@/styles/theme';
import { Visibility } from '@mui/icons-material';
import { Stack, Tooltip, IconButton } from '@mui/material';

interface IStockMovementsTableActionsProps {
  id: string;
  actionId: string | null;
  movementType: string;
  // eslint-disable-next-line no-unused-vars
  onViewMovement?: (movementId: string, type: string) => void;
  // eslint-disable-next-line no-unused-vars
  onViewMovementMouseEnter?: (movementId: string, type: string) => void;
}

export function StockMovementsTableActions({
  actionId,
  movementType,
  onViewMovement,
  onViewMovementMouseEnter,
}: IStockMovementsTableActionsProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {!!actionId && (
        <Tooltip title="Visualizar">
          <IconButton
            onMouseEnter={() =>
              onViewMovementMouseEnter &&
              onViewMovementMouseEnter(actionId, movementType)
            }
            onClick={(event) => {
              event.stopPropagation();
              return onViewMovement && onViewMovement(actionId, movementType);
            }}
            size="small"
            sx={{
              '&:hover': {
                color: theme.palette.primary.main,
              },
            }}
          >
            <Visibility fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}
