import { EntryDateField } from '@/@types/entries';
import { OutputDateField } from '@/@types/outputs';
import { Situation } from '@/@types/situation';
import { TStatus } from '@/@types/status';
import { capitalizeWord } from '@/utils/capitalizeWord';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { useState } from 'react';
import { Modal } from '../Modal';
import { ModalTitle } from '../Modal/common';

type TFilters = {
  startDate: string;
  endDate: string;
  showDeleted: boolean;
  status: TStatus;
  situationId?: string;
  dateField: EntryDateField | OutputDateField;
};

interface IFiltersModalProps {
  isOpen: boolean;
  defaultStartDate: Date;
  defaultEndDate: Date;
  showDeleted: boolean;
  defaultStatus: TStatus;
  defaultDateField: EntryDateField | OutputDateField;
  dateFields: Array<{
    id: EntryDateField | OutputDateField;
    label: string;
  }>;
  defaultSituationId?: string;
  situations?: Situation[];
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onApply: (filters: TFilters) => void;
}

const STATUS = [
  { id: 'todos', label: 'Todos' },
  { id: 'pendente', label: 'Pendente' },
  { id: 'finalizado', label: 'Finalizado' },
];

export function FiltersModal({
  isOpen,
  defaultStartDate,
  defaultEndDate,
  showDeleted,
  defaultStatus,
  defaultDateField,
  dateFields,
  defaultSituationId,
  situations,
  onClose,
  onApply,
}: IFiltersModalProps) {
  const [startDate, setStartDate] = useState<Date | null>(
    defaultStartDate || new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    defaultEndDate || new Date()
  );
  const [listDeleted, setListDeleted] = useState(showDeleted);
  const [status, setStatus] = useState(defaultStatus || 'todos');
  const [selectedSituation, setSelectedSituation] = useState(
    defaultSituationId || 'todos'
  );
  const [dateField, setDateField] = useState(defaultDateField || 'created_at');

  const hasSituations = !!situations?.length;

  const handleApply = () => {
    onApply({
      startDate: format(startDate as Date, 'yyyy-MM-dd'),
      endDate: format(endDate as Date, 'yyyy-MM-dd'),
      showDeleted: listDeleted,
      status,
      situationId: selectedSituation,
      dateField,
    });
    onClose();
  };
  return (
    <Modal open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <ModalTitle title="Filtros" onClose={onClose} />
      <DialogContent sx={{ p: 2 }}>
        <Stack spacing={2} pt={1}>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            spacing={2}
            pt={1}
          >
            <DatePicker
              label="Data Inicial"
              value={startDate}
              onChange={setStartDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
            <DatePicker
              label="Data Final"
              value={endDate}
              onChange={setEndDate}
              minDate={startDate as Date}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
          </Stack>
          <FormControl>
            <InputLabel id="date-field-select-label">Campo Data</InputLabel>
            <Select
              labelId="date-field-select-label"
              id="date-field-select"
              value={dateField}
              label="Campo Data"
              onChange={({ target }) => setDateField(target.value as any)}
            >
              {dateFields?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={hasSituations ? selectedSituation : status}
              label="Status"
              onChange={({ target }) =>
                hasSituations
                  ? setSelectedSituation(target.value)
                  : setStatus(target.value as any)
              }
            >
              {hasSituations
                ? [{ id: 'todos', name: 'Todos' }, ...situations].map(
                    (item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {capitalizeWord(item.name)}
                      </MenuItem>
                    )
                  )
                : STATUS.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!listDeleted}
                  onChange={() => setListDeleted(!listDeleted)}
                />
              }
              label="Listar Excluídos"
            />
          </FormGroup>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'center' }}>
        <Button onClick={onClose}>Fechar</Button>
        <Button variant="contained" onClick={handleApply}>
          Aplicar
        </Button>
      </DialogActions>
    </Modal>
  );
}
