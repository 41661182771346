import { StockMovement } from '@/@types/stock-movement';
import { ColumnDefinitionType } from '@/components/common/Table/table.utils';
import { StockMovementsTableActions } from './StockMovementsTableActions';

export const ProductStockMovementsTableColumns: ColumnDefinitionType<StockMovement>[] =
  [
    {
      key: 'previousQuantity',
      header: 'Qtde Anterior',
      sort: '',
    },
    {
      key: 'movementQuantity',
      header: 'Qtde Movimento',
      sort: '',
      render: (stockMovement) =>
        stockMovement.type === 'output'
          ? `-${stockMovement.movementQuantity}`
          : stockMovement.movementQuantity,
    },
    {
      key: 'finalQuantity',
      header: 'Qtde Final',
      sort: '',
    },
    {
      key: 'movementType',
      header: 'Tipo',
      sort: '',
    },
    {
      key: 'movementDate',
      header: 'Data',
      sort: '',
    },
    {
      key: 'id',
      header: 'Visualizar',
      sort: '',
      render: StockMovementsTableActions,
    },
  ];
