/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useMemo } from 'react';
import { Header, PrintInfo, Separator } from '@/components/common/PosPrint';
import { convertNumberToMoney } from '@/utils';
import { Output } from '@/@types/outputs';
import { DOCUMENT_TYPE_CORTESIA } from '@/modules/document_types/utils/documentTypes';
import { PrintLayout } from '@/@types/print';
import { PRINT_LAYOUT_1, PRINT_LAYOUT_2 } from '@/modules/utils/print-layouts';

interface IOutputPrintProps {
  output?: Output | null;
  containerRef: any | null;
  selectedLayout?: PrintLayout;
}

export function OutputPrint({
  output,
  containerRef,
  selectedLayout = PRINT_LAYOUT_1,
}: IOutputPrintProps) {
  const transformedOutputTotal = useMemo(() => {
    const fidelityInstallmentsTotal =
      output?.installments
        ?.filter(
          (installment) =>
            installment?.documentType?.type === DOCUMENT_TYPE_CORTESIA
        )
        .reduce((total, installment) => (total += installment.amount), 0) || 0;

    return convertNumberToMoney(
      (output?.total || 0) - fidelityInstallmentsTotal || 0
    );
  }, [output]);

  return (
    <div
      ref={containerRef}
      style={{
        printColorAdjust: 'economy',
        width: '7.2cm',
        color: 'black',
        padding: '0.25cm',
        fontFamily: 'Arial',
        fontSize: '11px',
      }}
    >
      <Separator />
      <Header />
      <Separator />
      <PrintInfo
        number={output?.number}
        date={output?.issueDate}
        operation={output?.operation}
        person={output?.customer}
        staff={output?.staff}
        paymentTerm={output?.paymentTerm}
        note={output?.note}
        selectedLayout={selectedLayout}
      />
      <Separator />
      <div
        style={{
          fontWeight: 'bold',
          paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
        }}
      >
        PRODUTOS
      </div>
      <Separator />
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <tbody>
          {output?.products?.map((product, index) => (
            <React.Fragment key={product.id}>
              <tr>
                <td
                  colSpan={5}
                  style={{
                    paddingLeft:
                      selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
                  }}
                >
                  {product.outputProduct.name}
                </td>
              </tr>
              <tr
                style={{
                  borderBottom:
                    index + 1 < output?.products?.length
                      ? '1px solid #333333'
                      : '',
                }}
              >
                <td
                  style={{
                    paddingLeft:
                      selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
                  }}
                >
                  {product.outputProduct?.number?.toString()?.padStart(4, '0')}
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {product.productQuantity.toFixed(2)}{' '}
                  {product.outputUnit.abbreviation} x
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {convertNumberToMoney(product?.unitaryValue || 0)} =
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {convertNumberToMoney(product?.subtotalProduct || 0)}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <Separator />
      <table style={{ width: '100%', textAlign: 'right' }}>
        <tbody>
          <tr>
            <td>Total Produtos:</td>
            <td>{convertNumberToMoney(output?.totalGross || 0)}</td>
          </tr>
          <tr>
            <td>Desconto:</td>
            <td>{convertNumberToMoney(output?.totalDiscount || 0)}</td>
          </tr>
          <tr>
            <td>
              <p style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                Total a Pagar:
              </p>
            </td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {transformedOutputTotal}
            </td>
          </tr>
          {output?.installments?.map((installment) => (
            <tr key={installment.id}>
              <td>{installment?.documentType?.name}</td>
              <td style={{ textAlign: 'right' }}>
                {convertNumberToMoney(installment?.amount || 0)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Separator />
      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
        NÃO É DOCUMENTO FISCAL
      </p>
      <Separator />
    </div>
  );
}
