type User = {
  permissions: string[];
  is_admin: boolean;
};

type ValidateUserPermissionsParams = {
  user: User;
  permissions?: string[];
  strategy?: 'some' | 'every';
};

export function validateUserPermissions({
  user,
  permissions = [],
  strategy = 'every',
}: ValidateUserPermissionsParams) {
  if (user.is_admin) return true;

  if (!permissions?.length) {
    return true;
  }

  const permissionValidator =
    strategy === 'some'
      ? permissions.some((permission) => user.permissions.includes(permission))
      : permissions.every((permission) =>
          user.permissions.includes(permission)
        );

  return permissionValidator;
}
