import { Staff } from '@/@types/staff';
import { PrintLayout } from '@/@types/print';
import { formatDateTime } from '@/utils';
import { PRINT_LAYOUT_1, PRINT_LAYOUT_2 } from '@/modules/utils/print-layouts';

interface PrintInfoSimpleProps {
  number?: string | number;
  date?: string;
  staff?: Staff;
  note?: string;
  selectedLayout?: PrintLayout;
}

export function PrintInfoSimple({
  number,
  date,
  staff,
  note,
  selectedLayout = PRINT_LAYOUT_1,
}: PrintInfoSimpleProps) {
  return (
    <>
      <table cellSpacing="0" style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td
              style={{
                paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
              }}
            >
              Número: {number}
            </td>
            <td style={{ textAlign: 'right' }}>
              Data: {formatDateTime(date as string)}
            </td>
          </tr>
        </tbody>
      </table>
      <ul style={{ listStyle: 'none' }}>
        <li
          style={{
            paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
          }}
        >
          Colaborador.: {staff?.number} - {staff?.name}
        </li>
        <li
          style={{
            paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
          }}
        >
          Observacoes: {note}
        </li>
      </ul>
    </>
  );
}
