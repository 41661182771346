import { Close } from '@mui/icons-material';
import { DialogTitle, IconButton } from '@mui/material';

type ModalTitleProps = {
  title?: string;
  onClose: () => void;
};

export function ModalTitle({ title = '', onClose }: ModalTitleProps) {
  return (
    <DialogTitle>
      {title}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
    </DialogTitle>
  );
}
