import { StyledTableCell } from '@/styles/staffs.styles';
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

interface IListPlaceholderProps {
  headers: string[];
  size?: 'small' | 'medium';
  minWidth?: number;
  rows?: number;
}

export function ListPlaceholder({
  headers,
  size,
  minWidth = 650,
  rows = 8,
}: IListPlaceholderProps) {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table size={size} sx={{ minWidth }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers?.map((header) => (
              <StyledTableCell key={header} sx={{ fontWeight: 'bold' }}>
                {header}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(rows).keys()]?.map((key) => (
            <TableRow key={key}>
              {headers?.map((header) => (
                <TableCell key={header}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
