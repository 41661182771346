export type MenuRoute = {
  name: string;
  path: string;
  permission: string;
};

export const menuRoutes: MenuRoute[] = [
  {
    name: 'Painel Administrativo',
    path: '/dashboard',
    permission: 'dashboard_read',
  },
  {
    name: 'Colaboradores',
    path: '/staffs',
    permission: 'staffs_read',
  },
  {
    name: 'Perfis de Usuário',
    path: '/roles',
    permission: 'roles_read',
  },
  {
    name: 'Usuários',
    path: '/users',
    permission: 'users_read',
  },
  {
    name: 'Unidades',
    path: '/units',
    permission: 'units_read',
  },
  {
    name: 'Categorias',
    path: '/categories',
    permission: 'categories_read',
  },
  {
    name: 'Produtos',
    path: '/products',
    permission: 'products_read',
  },
  {
    name: 'Tabelas de Preço',
    path: '/price_tables',
    permission: 'price_tables_read',
  },
  {
    name: 'Tipos de Operação',
    path: '/operations',
    permission: 'operations_read',
  },
  {
    name: 'Tipos de Documento',
    path: '/document_types',
    permission: 'document_types_read',
  },
  {
    name: 'Condições de Pagamento',
    path: '/payment_terms',
    permission: 'payment_terms_read',
  },
  {
    name: 'Situações',
    path: '/situations',
    permission: 'situations_read',
  },
  {
    name: 'Motivos de Cancelamento',
    path: '/deletion_reasons',
    permission: 'deletion_reasons_read',
  },
  {
    name: 'Clientes',
    path: '/customers',
    permission: 'customers_read',
  },
  {
    name: 'Fornecedores',
    path: '/vendors',
    permission: 'vendors_read',
  },
  {
    name: 'Movimentos de Saldo',
    path: '/movements_balance',
    permission: 'movements_balance_read',
  },
  {
    name: 'Outras Entradas',
    path: '/other_entries',
    permission: 'other_entries_read',
  },
  {
    name: 'Outras Saídas',
    path: '/other_outputs',
    permission: 'other_outputs_read',
  },
  {
    name: 'Compras',
    path: '/purchases',
    permission: 'purchases_read',
  },
  {
    name: 'Vendas',
    path: '/sales',
    permission: 'sales_read',
  },
  {
    name: 'Televendas',
    path: '/telesales',
    permission: 'telesales_read',
  },
  {
    name: 'Configurações',
    path: '/settings',
    permission: 'settings_read',
  },
];
