import React, { ReactNode } from 'react';

import { useCan } from '@/modules/core/hooks';

interface ICanProps {
  children: ReactNode;
  permissions?: string[];
  strategy?: 'some' | 'every';
}

export function Can({ children, permissions, strategy = 'every' }: ICanProps) {
  const userCanSeeComponent = useCan({ permissions, strategy });

  if (!userCanSeeComponent) return null;

  return <>{children}</>;
}
