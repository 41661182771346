import { Entry } from '@/@types/entries';
import { PrintLayout } from '@/@types/print';
import { Header, PrintInfo, Separator } from '@/components/common/PosPrint';
import { PRINT_LAYOUT_1, PRINT_LAYOUT_2 } from '@/modules/utils/print-layouts';
import { convertNumberToMoney } from '@/utils';
import React from 'react';

interface IEntryPrintProps {
  entry?: Entry;
  containerRef: any | null;
  selectedLayout?: PrintLayout;
}

export function EntryPrint({
  entry,
  containerRef,
  selectedLayout = PRINT_LAYOUT_1,
}: IEntryPrintProps) {
  return (
    <div
      ref={containerRef}
      style={{
        printColorAdjust: 'economy',
        width: '7.2cm',
        color: 'black',
        padding: '0.25cm',
        fontFamily: 'Arial',
        fontSize: '11px',
      }}
    >
      <Separator />
      <Header />
      <Separator />
      <PrintInfo
        number={entry?.number}
        date={entry?.issueDate}
        operation={entry?.operation}
        person={entry?.vendor}
        personLabel="Fornecedor"
        staff={entry?.staff}
        staffLabel="Colaborador"
        note={entry?.note}
        selectedLayout={selectedLayout}
      />
      <Separator />
      <div
        style={{
          fontWeight: 'bold',
          paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
        }}
      >
        PRODUTOS
      </div>
      <Separator />
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <tbody>
          {entry?.products?.map((product, index) => (
            <React.Fragment key={product.id}>
              <tr>
                <td
                  colSpan={5}
                  style={{
                    paddingLeft:
                      selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
                  }}
                >
                  {product.entryProduct.name}
                </td>
              </tr>
              <tr
                style={{
                  borderBottom:
                    index + 1 < entry?.products?.length
                      ? '1px solid #333333'
                      : '',
                }}
              >
                <td
                  style={{
                    paddingLeft:
                      selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
                  }}
                >
                  {product.entryProduct?.number?.toString()?.padStart(4, '0')}
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {product.productQuantity.toFixed(2)}{' '}
                  {product.entryUnit.abbreviation} x
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {convertNumberToMoney(product?.unitaryValue || 0)} =
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {convertNumberToMoney(product?.subtotalProduct || 0)}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <Separator />
      <table style={{ width: '100%', textAlign: 'right' }}>
        <tbody>
          <tr>
            <td>Total Produtos:</td>
            <td>{convertNumberToMoney(entry?.totalGross || 0)}</td>
          </tr>
          <tr>
            <td>Desconto:</td>
            <td>{convertNumberToMoney(entry?.totalDiscount || 0)}</td>
          </tr>
          <tr>
            <td>Total:</td>
            <td style={{ textAlign: 'right' }}>
              {convertNumberToMoney(entry?.total || 0)}
            </td>
          </tr>
        </tbody>
      </table>
      <Separator />
      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
        NÃO É DOCUMENTO FISCAL
      </p>
      <Separator />
    </div>
  );
}
