import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import { TDocumentType } from '@/@types/document-type';
import * as zod from 'zod';
import {
  DOCUMENT_TYPES_TYPES,
  DOCUMENT_TYPE_ESPECIE,
} from '@/modules/document_types/utils/documentTypes';

const documentTypeFormSchema = zod.object({
  name: zod
    .string()
    .min(2, 'Informe o nome do tipo de documento')
    .transform((name) => name.trim().toUpperCase()),
  type: zod.string().min(2, 'Informe o tipo'),
});

export type DocumentTypeFormData = zod.infer<typeof documentTypeFormSchema>;

interface IDocumentTypeFormProps {
  documentType?: TDocumentType;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: DocumentTypeFormData) => void;
  onCancel: () => void;
}

export function DocumentTypeForm({
  documentType,
  isLoading,
  onSubmit,
  onCancel,
}: IDocumentTypeFormProps) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<DocumentTypeFormData>({
    resolver: zodResolver(documentTypeFormSchema),
    defaultValues: {
      name: documentType?.name || '',
      type: documentType?.type || DOCUMENT_TYPE_ESPECIE,
    },
  });

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers sx={{ py: 3, px: 2 }}>
        <Stack spacing={3}>
          <TextField
            type="text"
            label="Nome"
            autoFocus
            error={!!errors.name}
            helperText={errors.name?.message}
            disabled={isLoading}
            {...register('name')}
          />
          <FormControl fullWidth error={!!errors.type}>
            <Controller
              name="type"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <Autocomplete
                  options={DOCUMENT_TYPES_TYPES}
                  getOptionLabel={(option) => option.name}
                  noOptionsText="Nenhum registro encontrado"
                  autoHighlight
                  value={
                    value
                      ? DOCUMENT_TYPES_TYPES?.find(
                          (type) => type.value === value
                        )
                      : null
                  }
                  onChange={(_, newValue) =>
                    onChange(newValue ? newValue.value : '')
                  }
                  renderInput={(params) => (
                    <TextField
                      {...field}
                      {...params}
                      label="Tipo"
                      error={!!errors.type}
                    />
                  )}
                />
              )}
            />
            {!!errors.type && (
              <FormHelperText>{errors.type.message}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit" disabled={isLoading}>
          Salvar
        </Button>
      </DialogActions>
    </Box>
  );
}
