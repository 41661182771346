import { Help } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import { PrintLayout } from '@/@types/print';
import { PRINT_LAYOUTS } from '@/modules/utils/print-layouts';
import { Modal } from '../Modal';

interface IConfirmPrintModalProps {
  isOpen: boolean;
  isLoading: boolean;
  title?: string;
  message?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onClose: () => void;
  onConfirm: () => void;
  selectedLayout: PrintLayout;
  // eslint-disable-next-line no-unused-vars
  onLayoutChange: (layout: PrintLayout) => void;
  copies: number;
  // eslint-disable-next-line no-unused-vars
  onCopiesChange: (copies: number) => void;
}

export function ConfirmPrintModal({
  isOpen,
  isLoading,
  onClose,
  title,
  message = 'Você tem certeza que deseja realizar está ação?',
  cancelButtonText = 'Cancelar',
  confirmButtonText = 'Confirmar',
  onConfirm,
  selectedLayout,
  onLayoutChange,
  copies,
  onCopiesChange,
}: IConfirmPrintModalProps) {
  return (
    <Modal open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      {isLoading ? <LinearProgress /> : null}
      <DialogContent sx={{ p: 2 }}>
        <Stack alignItems="center" justifyContent="center">
          <Help color="warning" sx={{ fontSize: '3.5rem' }} />
          <Stack sx={{ textAlign: 'center', mt: 2, mb: 3 }}>
            {title ? (
              <DialogContentText variant="h5" fontWeight="bold">
                {title}
              </DialogContentText>
            ) : null}
            <DialogContentText sx={{ textAlign: 'center' }}>
              {message}
            </DialogContentText>
          </Stack>

          <Stack spacing={2} sx={{ width: { xs: '100%', sm: '80%' } }}>
            <FormControl fullWidth>
              <InputLabel id="print-layout-label">
                Layout de Impressão
              </InputLabel>
              <Select
                labelId="print-layout-label"
                value={selectedLayout}
                label="Layout de Impressão"
                onChange={(e) => onLayoutChange(e.target.value as PrintLayout)}
                disabled={isLoading}
              >
                {PRINT_LAYOUTS.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              type="number"
              label="Número de Cópias"
              value={copies}
              onChange={(e) =>
                onCopiesChange(
                  Math.min(10, Math.max(1, parseInt(e.target.value, 10) || 1))
                )
              }
              inputProps={{ min: 1, max: 10 }}
              disabled={isLoading}
              fullWidth
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'center' }}>
        <Button onClick={onClose} disabled={isLoading} autoFocus>
          {cancelButtonText}
        </Button>
        <Button variant="contained" onClick={onConfirm} disabled={isLoading}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Modal>
  );
}
