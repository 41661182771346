import { Settings } from '@/@types/settings';
import { api } from '@/services/apiClient';
import { queryClient } from '@/services/queryClient';
import { useMutation } from '@tanstack/react-query';

type UpdateSettingsProps = Settings;
type UpdateSettingsResponse = Settings;

async function updateSettings({
  productUnitId,
  productCategoryId,
  saleOperationId,
  telesalesOperationId,
  telesalesGenerateMovementOperationId,
  telesalesGenerateMovementCustomerId,
  saleCustomerId,
  salePaymentTermId,
  purchaseOperationId,
  purchaseVendorId,
  otherOutputOperationId,
  otherOutputCustomerId,
  otherOutputPaymentTermId,
  otherEntryOperationId,
  otherEntryVendorId,
  salesInitialSituationId,
  salesTransitSituationId,
  salesFinishedSituationId,
}: UpdateSettingsProps): Promise<UpdateSettingsResponse> {
  const { data } = await api.put('/settings', {
    product_unit_id: productUnitId || null,
    product_category_id: productCategoryId || null,
    sale_operation_id: saleOperationId || null,
    telesales_operation_id: telesalesOperationId || null,
    telesales_generate_movement_operation_id:
      telesalesGenerateMovementOperationId || null,
    telesales_generate_movement_customer_id:
      telesalesGenerateMovementCustomerId || null,
    sale_customer_id: saleCustomerId || null,
    sale_payment_term_id: salePaymentTermId || null,
    purchase_operation_id: purchaseOperationId || null,
    purchase_vendor_id: purchaseVendorId || null,
    other_output_operation_id: otherOutputOperationId || null,
    other_output_customer_id: otherOutputCustomerId || null,
    other_output_payment_term_id: otherOutputPaymentTermId || null,
    other_entry_operation_id: otherEntryOperationId || null,
    other_entry_vendor_id: otherEntryVendorId || null,
    sales_initial_situation_id: salesInitialSituationId || null,
    sales_transit_situation_id: salesTransitSituationId || null,
    sales_finished_situation_id: salesFinishedSituationId || null,
  });

  return data;
}

export function useUpdateSettings() {
  const updateSettingsMutation = useMutation({
    mutationFn: (settingsData: UpdateSettingsProps) =>
      updateSettings({
        ...settingsData,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['settings']);
    },
  });

  return {
    updateSettingsMutation,
  };
}
