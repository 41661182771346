import { capitalizeWord } from '@/utils/capitalizeWord';

export const DOCUMENT_TYPE_ESPECIE = 'espécie';
export const DOCUMENT_TYPE_CARTAO = 'cartão';
export const DOCUMENT_TYPE_CHEQUE = 'cheque';
export const DOCUMENT_TYPE_CORTESIA = 'cortesia';
export const DOCUMENT_TYPE_DOCUMENTO = 'documento';

export const DOCUMENT_TYPES_TYPES = [
  {
    id: 1,
    name: capitalizeWord(DOCUMENT_TYPE_ESPECIE),
    value: DOCUMENT_TYPE_ESPECIE,
  },
  {
    id: 1,
    name: capitalizeWord(DOCUMENT_TYPE_CARTAO),
    value: DOCUMENT_TYPE_CARTAO,
  },
  {
    id: 1,
    name: capitalizeWord(DOCUMENT_TYPE_CHEQUE),
    value: DOCUMENT_TYPE_CHEQUE,
  },
  {
    id: 1,
    name: capitalizeWord(DOCUMENT_TYPE_CORTESIA),
    value: DOCUMENT_TYPE_CORTESIA,
  },
  {
    id: 1,
    name: capitalizeWord(DOCUMENT_TYPE_DOCUMENTO),
    value: DOCUMENT_TYPE_DOCUMENTO,
  },
];
