import { Permission } from '@/@types/permission';
import { AuthContext } from '@/contexts/AuthContext';
import { useContext } from 'react';
import { validateUserPermissions } from '../auth/utils/validateUserPermissions';

type UseCanParams = {
  permissions?: string[];
  strategy?: 'some' | 'every';
};

export function useCan({ permissions, strategy = 'every' }: UseCanParams) {
  const { user, isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated) {
    return false;
  }

  const userRolePermissions = user?.role?.permissions.map(
    (permission: Permission) => permission.name
  );

  const userHasValidPermissions = validateUserPermissions({
    user: { is_admin: !!user?.is_admin, permissions: userRolePermissions },
    permissions,
    strategy,
  });

  return userHasValidPermissions;
}
