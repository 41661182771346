import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

interface ICheckboxLabelProps {
  isChecked: boolean;
}
export function CheckboxLabel({ isChecked }: ICheckboxLabelProps) {
  if (isChecked) {
    return <CheckBox color="primary" />;
  }

  return <CheckBoxOutlineBlank />;
}
