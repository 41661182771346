import { Customer } from '@/@types/customer';
import { Operation } from '@/@types/operation';
import { PaymentTerm } from '@/@types/payment-term';
import { PrintLayout } from '@/@types/print';
import { Staff } from '@/@types/staff';
import { Vendor } from '@/@types/vendor';
import { PRINT_LAYOUT_1, PRINT_LAYOUT_2 } from '@/modules/utils/print-layouts';
import { formatDateTime } from '@/utils';

interface PrintInfoProps {
  number?: string | number;
  date?: string;
  operation?: Operation;
  person?: Customer | Vendor;
  personLabel?: string;
  staff?: Staff;
  staffLabel?: string;
  paymentTerm?: PaymentTerm | null;
  note?: string;
  selectedLayout?: PrintLayout;
}

export function PrintInfo({
  number,
  date,
  operation,
  person,
  personLabel = 'Cliente........:',
  staff,
  staffLabel = 'Vendedor....:',
  paymentTerm,
  note,
  selectedLayout = PRINT_LAYOUT_1,
}: PrintInfoProps) {
  return (
    <>
      <table cellSpacing="0" style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td
              style={{
                paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
              }}
            >
              Número: {number}
            </td>
            <td style={{ textAlign: 'right' }}>
              Data: {formatDateTime(date as string)}
            </td>
          </tr>
        </tbody>
      </table>
      <ul style={{ listStyle: 'none' }}>
        <li
          style={{
            paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
          }}
        >
          Tipo............: {operation?.number} - {operation?.name}
        </li>
        <li
          style={{
            paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
          }}
        >
          {personLabel} {person?.number} - {person?.name}
        </li>
        <li
          style={{
            paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
          }}
        >
          Endereço....: {person?.streetName || '-'}
        </li>
        <li
          style={{
            paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
          }}
        >
          {person?.streetNumber
            ? `Nº ${person?.streetNumber?.padStart(4, '0')}`
            : ''}{' '}
          {person?.neighborhood}
        </li>
        <li
          style={{
            paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
          }}
        >
          {person?.complement}
        </li>
        <li
          style={{
            paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
          }}
        >
          {person?.landmark}
        </li>
        <li
          style={{
            paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
          }}
        >
          {staffLabel} {staff?.number} - {staff?.name}
        </li>
        {paymentTerm ? (
          <li
            style={{
              paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
            }}
          >
            Cond. Pagto: {paymentTerm?.number} - {paymentTerm?.name}
          </li>
        ) : null}
        {note ? (
          <li
            style={{
              paddingLeft: selectedLayout === PRINT_LAYOUT_2 ? '0.25cm' : '0',
            }}
          >
            Observacoes: {note}
          </li>
        ) : null}
      </ul>
    </>
  );
}
